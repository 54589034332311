export const promocodes = [
    {
        name: "iwantitnow",
        value: 10,
        type: "persent"
    },
    {
        name: "sicvolofriend",
        value: 10,
        type: "persent"
    },
    {
        name: "preciouslife",
        value: 10,
        type: "persent"
    },
    {
        name: "sicvolofamily",
        value: 15,
        type: "persent"
    },
    {
        name: "iwantitall",
        value: 15,
        type: "persent"
    },
    {
        name: "goforluxury",
        value: 15,
        type: "persent"
    },
    {
        name: "Verena20",
        value: 20,
        type: "persent"
    },
    {
        name: "Svit",
        value: 20,
        type: "persent"
    },
   
]